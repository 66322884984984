import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { useEffect, useState } from "react";
import { NotificationTypeEnum } from "../../Utils";

type ParamsType = {
  type: NotificationTypeEnum;
  page?: string;
};

export default function ProtectedNotifications({ children }: React.PropsWithChildren) {
  const { type, page } = useParams<ParamsType>() as ParamsType;
  const navigate = useNavigate();

  const [canVisit, setCanVisit] = useState(false);

  useEffect(() => {
    if (!(page && Number.isInteger(Number.parseInt(page)) && Number.parseInt(page) > 0)) {
      navigate(generatePath(Routes.PROFIL_NOTIFICATIONS, { type: type, page: "1" }));
      return;
    }

    const newCanVisit = Object.values(NotificationTypeEnum).includes(type);

    if (!newCanVisit) {
      navigate(Routes.PROFIL_ACTIVITY, { replace: true });
      return;
    }

    setCanVisit(newCanVisit);
  }, [navigate, page, type]);

  return canVisit ? children : null;
}
