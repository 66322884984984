import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HelpEnigma() {
  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1>Banque d'énigmes mathématiques</h1>

      <p className="mt-3">Cette banque recense des problèmes de recherche à destination des élèves de la GS à la sixième.</p>

      <p className="mt-3">
        Les énigmes GS/CP, CE1/CE2 et CM1/CM2/6° sont conçues par Jean-Claude Rolland de la circonscription d'Epinay-sur-Seine. Pour en savoir plus :{" "}
        <a href="https://ien-epinay.circo.ac-creteil.fr/spip.php?page=article&id_article=1632" rel="noreferrer" target="_blank">
          cliquer ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>

      <p className="mt-3">
        Les énigmes pour le cycle 3 sont issues du dispositif EurêkaMaths. Pour en savoir plus :{" "}
        <a href="https://www.mathsenvie.fr/eurekamaths/" rel="noreferrer" target="_blank">
          cliquer ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>

      <p className="mt-3">Les autres énigmes proviennent d'un travail partenarial entre CANOPE93, la mission mathématiques 93 et M@ths'n Co.</p>

      <p className="mt-3">Typologie des énigmes :</p>
      <ul>
        <li>Problèmes algébriques</li>
        <li>Problèmes algorithmiques</li>
        <li>Problèmes de dénombrement</li>
        <li>Problèmes d'optimisation</li>
        <li>Problèmes de géométrie</li>
      </ul>

      <p className="mt-3">Un clic sur le bouton « filtre » vous permet d'ouvrir la fenêtre des filtres, puis :</p>
      <ul>
        <li>sélectionnez le ou les types de problèmes à afficher ;</li>
        <li>cliquez sur « Filtrer ».</li>
      </ul>

      <p>La liste de problèmes s'actualise alors en fonction de vos critères. Vous pouvez naviguer à travers les pages si tous les problèmes ne peuvent s'afficher sur une seule page.</p>
    </div>
  );
}

export default HelpEnigma;
