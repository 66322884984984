import React from "react";
import ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Link, Navigate, RouterProvider, createBrowserRouter, isRouteErrorResponse, useRouteError } from "react-router-dom";

import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/app-1.0.0.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BaseLayout, LayoutPage } from "./components";
import { ProtectedConsult, ProtectedContribute, ProtectedEdition, ProtectedEnigma, ProtectedNotifications, ProtectedRoute } from "./components/ProtectedPagesLayout";
import { Routes } from "./routes";
import { Console, Consult, ConsultEnigma, Contribute, Home, MathWeek, Problem } from "./pages";
import { AdminAi, AdminCsv, AdminHome, AdminLayout, AdminMail, AdminMessage, AdminReview } from "./pages/Admin";
import { Help, HelpEnigma, HelpPhoto, HelpPhotoProblem, HelpTextProblem } from "./pages/Help";
import { ProfilActivity, ProfilGrade, ProfilLayout, ProfilParameters } from "./pages/Profil";
import { NotificationsModal } from "./components/Modal";
import type { ApiError } from "./hooks/useAPI/api";
import { Helmet, HelmetProvider } from "react-helmet-async";

// For the v5
// declare module '@tanstack/react-query' {
//     interface Register {
//       defaultError: ApiError
//     }
// }

const router = createBrowserRouter(
  [
    {
      element: <BaseLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          element: <LayoutPage />,
          children: [
            {
              path: Routes.OLD_HOME,
              element: <Navigate to={Routes.HOME} replace />,
            },
            {
              path: Routes.HOME,
              element: <Home />,
            },
            {
              path: Routes.PROBLEM,
              element: <Problem />,
            },
            {
              path: Routes.CONSULT,
              element: (
                <ProtectedConsult>
                  <Consult />
                </ProtectedConsult>
              ),
            },
            {
              path: Routes.CONSULT_ENIGMA,
              element: (
                <ProtectedEnigma>
                  <ConsultEnigma />,
                </ProtectedEnigma>
              ),
            },
            {
              path: Routes.EDITION,
              element: (
                <ProtectedRoute>
                  <ProtectedEdition>
                    <Consult isEdition />
                  </ProtectedEdition>
                </ProtectedRoute>
              ),
            },
            {
              path: Routes.CONTRIBUTE,
              element: (
                <ProtectedRoute>
                  <ProtectedContribute>
                    <Contribute />
                  </ProtectedContribute>
                </ProtectedRoute>
              ),
            },
            {
              path: Routes.HELP,
              element: <Help />,
            },
            {
              path: Routes.HELP_TEXT_PROBLEM,
              element: <HelpTextProblem />,
            },
            {
              path: Routes.HELP_PHOTO,
              element: <HelpPhoto />,
            },
            {
              path: Routes.HELP_PHOTO_PROBLEM,
              element: <HelpPhotoProblem />,
            },
            {
              path: Routes.HELP_ENIGMA,
              element: <HelpEnigma />,
            },
            {
              path: Routes.PROFIL,
              element: (
                <ProtectedRoute>
                  <ProfilLayout />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: Routes.PROFIL_ACTIVITY,
                  element: <ProfilActivity />,
                },
                {
                  path: Routes.PROFIL_GRADE,
                  element: <ProfilGrade />,
                },
                {
                  path: Routes.PROFIL_PARAMETERS,
                  element: <ProfilParameters />,
                },
                {
                  path: Routes.PROFIL_NOTIFICATIONS,
                  element: (
                    <ProtectedNotifications>
                      <NotificationsModal />
                    </ProtectedNotifications>
                  ),
                },
              ],
            },
            {
              path: Routes.ADMIN,
              element: (
                <ProtectedRoute needAdmin>
                  <AdminLayout />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: "",
                  element: <AdminHome />,
                },
                {
                  path: Routes.ADMIN_REVIEW,
                  element: <AdminReview />,
                },
                {
                  path: Routes.ADMIN_MAIL,
                  element: <AdminMail />,
                },
                {
                  path: Routes.ADMIN_MESSAGE,
                  element: <AdminMessage />,
                },
                {
                  path: Routes.ADMIN_CSV,
                  element: <AdminCsv />,
                },
                {
                  path: Routes.ADMIN_AI,
                  element: <AdminAi />,
                },
              ],
            },
            {
              path: Routes.CONSOLE,
              element: (
                <ProtectedRoute needSuperAdmin>
                  <Console />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: Routes.MATH_WEEK,
          element: <MathWeek />,
        },
      ],
    },
  ],
  { basename: process.env.REACT_APP_BASENAME_ROUTER }
);

function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
          <h1>Erreur 404 :(</h1>
          <p className="mt-3 lead fw-bold">La page que vous cherchez n'existe plus ou n'as jamais existé.</p>
          <Link className="btn btn-ternary mt-3" to={Routes.HOME}>
            Retourner à la page d'acceuil
          </Link>
        </div>
      );
    }
  }

  return (
    <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
      <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
      <h1>Erreur :(</h1>
      <p className="mt-3 lead fw-bold">Une erreur est survenue.</p>
      <Link className="btn btn-ternary mt-3" to={Routes.HOME}>
        Retourner à la page d'acceuil
      </Link>
    </div>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, _error): boolean => {
        const error = _error as ApiError;

        if (failureCount === 2) {
          return false;
        }
        if (error.code === 404 || error.code === 403 || error.code === 400 || error.code === 500 || error.code === 503) {
          return false;
        }
        return true;
      },
      refetchOnWindowFocus: false,
      networkMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "always" : "online",
    },
    mutations: {
      networkMode: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "always" : "online",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
