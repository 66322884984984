function AdminCsv() {
  return (
    <>
      <a className="btn btn-primary me-5" target="_blank" rel="noreferrer" href={process.env.REACT_APP_BASE_API_URL + "/api/admin/export-users-csv"}>
        Exporter le csv des utilisateurs
      </a>
      <a className="btn btn-primary" target="_blank" rel="noreferrer" href={process.env.REACT_APP_BASE_API_URL + "/api/admin/export-problems-csv"}>
        Exporter le csv des problèmes
      </a>
    </>
  );
}

export default AdminCsv;
