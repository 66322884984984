import { useForm } from "react-hook-form";
import { ApiError, useAPI } from "../../hooks";
import { useMutation } from "@tanstack/react-query";
import { FormButtonLoading } from "../../components";
import { MutationData } from "../../types";
import { Alert } from "react-bootstrap";

type FormValues = {
  title: string;
  content: string;
};

const defaultValues: FormValues = {
  title: "",
  content: "",
};

function AdminMessage() {
  const api = useAPI();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const sendMessage = useMutation<MutationData, ApiError, FormValues>({
    mutationFn: (messageData) => {
      return api.message.sendMessage(messageData);
    },
    onSuccess: () => {
      reset();
    },
  });

  const onSubmit = (data: FormValues) => {
    sendMessage.mutate(data);
  };

  return (
    <>
      <h5 className="mb-2">Poster un message</h5>
      {sendMessage.isError && <Alert variant={sendMessage.error.variant}>{sendMessage.error.message}</Alert>}
      {sendMessage.isSuccess && <Alert variant="success">{sendMessage.data.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-3">
          <label htmlFor="message-title" className="form-label">
            Sujet
          </label>
          <input
            type="text"
            className="form-control"
            id="message-title"
            {...register("title", {
              required: { value: true, message: "Veuillez saisir le titre du message." },
              maxLength: { value: 128, message: "Le titre ne peut pas contenir plus de 128 caractères." },
            })}
            placeholder="Titre"
            required
            autoFocus
          />
          <div className="form-text">128 caractères maximum</div>
          {errors.title && <div className="invalid-feedback d-block">{errors.title.message}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="message-content" className="form-label">
            Contenu
          </label>
          <textarea
            className="form-control"
            id="message-content"
            {...register("content", {
              required: { value: true, message: "Veuillez saisir le contenu du message." },
              maxLength: { value: 1024, message: "Le contenu ne peut pas contenir plus de 1024 caractères." },
            })}
            style={{ height: 150 }}
            placeholder="Rédigez votre message"
            required
          ></textarea>
          <div className="form-text">1024 caractères maximum</div>
          {errors.content && <div className="invalid-feedback d-block">{errors.content.message}</div>}
        </div>
        <p className="fst-italic">* Attention le message sera rendu public et TOUS les membres seront notifiés</p>
        <FormButtonLoading label="Poster *" type="submit" isLoading={sendMessage.isLoading} className="btn btn-primary" />
      </form>
    </>
  );
}

export default AdminMessage;
