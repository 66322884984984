import { Suspense } from "react";
import { useAPI, useUtils } from "../../hooks";
import { Chart } from "../../components";
import { ProblemCommentType } from "../../types";
import { useQuery } from "@tanstack/react-query";
import { AdminLastCommentsKey } from "./query";
import { Routes } from "../../routes";
import { generatePath, Link } from "react-router-dom";

function AdminHome() {
  const { dateAgo } = useUtils();
  const api = useAPI();

  const { data: lastComments } = useQuery<ProblemCommentType[]>(AdminLastCommentsKey, api.problem.getLastComments);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Chart />
      </Suspense>
      <div>
        {lastComments?.map((comment) => (
          <p key={comment.id}>
            {comment.username} a commenté un <Link to={generatePath(Routes.PROBLEM, { id: comment.problemId.toString() })}>problème</Link> {dateAgo(comment.createdAt).toLowerCase()}
          </p>
        ))}
      </div>
    </>
  );
}

export default AdminHome;
