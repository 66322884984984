import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { ApiError, useAPI } from "../../hooks";
import { FormButtonLoading } from "../../components";
import { MutationData } from "../../types";
import { Alert } from "react-bootstrap";
import { LinearProgress } from "@mui/material";
import { useState } from "react";
import { HttpStatusCode } from "axios";

type FormValues = {
  subject: string;
  content: string;
};

const defaultValues: FormValues = {
  subject: "",
  content: "",
};

function AdminMail() {
  const api = useAPI();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });

  const sendEmail = useMutation<MutationData, ApiError, { data: FormValues; offset: number; limit: number }>({
    mutationFn: (data) => {
      return api.admin.sendEmail(data.data, data.offset, data.limit);
    },
  });

  const [value, setValue] = useState(0);
  const [results, setResults] = useState<{ message: string; status: HttpStatusCode }[]>([]);

  async function lunch(data: FormValues) {
    setResults([]);

    let numberOfUsers = 0;

    try {
      numberOfUsers = await api.admin.emailsSubscribedNumber();
    } catch (error) {
      return;
    }

    const AMOUT_USER_PER_REQUEST = 100;

    let promisesMutation: Promise<MutationData>[] = [];
    for (let i = 0; i < Math.ceil(numberOfUsers / AMOUT_USER_PER_REQUEST); i += 1) {
      const promise = sendEmail.mutateAsync({ data, offset: i * AMOUT_USER_PER_REQUEST, limit: AMOUT_USER_PER_REQUEST });
      promisesMutation.push(promise);

      promise
        .then((data) => {
          setResults((old) => [...old, { message: data.message, status: 200 }]);
        })
        .catch((error) => {
          if (error instanceof ApiError) {
            setResults((old) => [...old, { message: error.message, status: error.code }]);
          }
        })
        .finally(() => {
          setValue((old) => old + 100 / Math.ceil(numberOfUsers / AMOUT_USER_PER_REQUEST));
        });
    }

    try {
      await Promise.all(promisesMutation);
    } catch (error) {}

    setTimeout(() => {
      setValue(0);
      reset();
    }, 2000);
  }

  const onSubmit = (data: FormValues) => {
    lunch(data);
  };

  return (
    <>
      <h5 className="mb-2">Envoyer un courriel</h5>
      {sendEmail.isError && <Alert variant={sendEmail.error.variant}>{sendEmail.error.message}</Alert>}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="mb-3">
          <label htmlFor="mail-subject" className="form-label">
            Sujet
          </label>
          <input
            type="text"
            className="form-control"
            id="mail-subject"
            {...register("subject", {
              maxLength: { value: 128, message: "Le sujet ne peut pas contenir plus de 128 caractères." },
            })}
            placeholder="Sujet"
            autoFocus
          />
          <div className="form-text">128 caractères maximum</div>
          {errors.subject && <div className="invalid-feedback d-block">{errors.subject.message}</div>}
        </div>
        <div className="mb-3">
          <label htmlFor="mail-content" className="form-label">
            Contenu
          </label>
          <textarea
            className="form-control"
            id="mail-content"
            {...register("content", {
              required: { value: true, message: "Veuillez saisir le contenu du courriel." },
              maxLength: { value: 1024, message: "Le contenu ne peut pas contenir plus de 1024 caractères." },
            })}
            style={{ height: 150 }}
            placeholder="Rédigez votre courriel"
            required
          ></textarea>
          <div className="form-text">1024 caractères maximum</div>
          {errors.content && <div className="invalid-feedback d-block">{errors.content.message}</div>}
        </div>
        <p className="fst-italic">* Attention le courriel sera envoyé à TOUS les membres</p>
        <FormButtonLoading label="Envoyer *" type="submit" isLoading={sendEmail.isLoading} className="btn btn-primary" />
        {/* Montly emails */}
        <div className="text-center">
          {<LinearProgress color="success" className="my-3 mx-auto" value={value} variant="determinate" />}
          {results.map((result, index) => (
            <div key={index} className={result.status === 200 ? "text-success" : "text-danger"}>
              {result.message}
            </div>
          ))}
        </div>
      </form>
    </>
  );
}

export default AdminMail;
