import { useEffect, useRef, useState } from "react";
import { ApiError, useAPI, useUtils } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { NotificationsDataKey, NotificationsTypePageKey, useNotifications } from "../../contexts/notifications";
import { Link, NavLink, generatePath, useLocation, useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../routes";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LinearProgress } from "@mui/material";
import { Pagination } from "..";
import { ModalTypeEnum, NotificationTypeEnum, ProblemTypeEnum } from "../../Utils";
import { useModals } from "../../contexts/modals";
import { Alert, CloseButton, Modal } from "react-bootstrap";

type ParamsType = {
  type: NotificationTypeEnum;
  page: string;
};

type NotificationLike = {
  type: NotificationTypeEnum.Like;
  date: number;
  username: string;
  id: number;
  p_discr: ProblemTypeEnum;
};

type NotificationComment = {
  type: NotificationTypeEnum.Comment;
  date: number;
  username: string;
  id: number;
  p_id: number;
  p_discr: ProblemTypeEnum;
};

type NotificationMessage = {
  type: NotificationTypeEnum.Message;
  date: number;
  username: string;
  id: number;
  title: string;
  content: string;
};

type NotificationsData = NotificationLike[] | NotificationComment[] | NotificationMessage[];

type NotificationsQuery = {
  page: number;
  totalPage: number;
  notifications: NotificationsData;
};

function NotificationsModal() {
  const [show, setShow] = useState(true);
  const { type, page } = useParams<ParamsType>() as ParamsType;
  const api = useAPI();
  const { dateAgo, transFromDiscr } = useUtils();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const regexPathname = /^(.*\/)\d+$/gi.exec(pathname);

  const { notificationsNumber } = useNotifications();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setShow(false);
    navigate(Routes.PROFIL_ACTIVITY);
    queryClient.invalidateQueries(NotificationsDataKey, { exact: true });
  };

  const { isLoading, isError, error, data } = useQuery<NotificationsQuery, ApiError>(NotificationsTypePageKey(type, page), () => api.profil.getNotifications(type, Number.parseInt(page)), {
    staleTime: 60 * 1000, // 1 min
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <h4>Notifications</h4>
        <CloseButton variant="white" onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <ul className="nav nav-tabs">
            <li className="nav-item position-relative">
              <NavLink
                to={generatePath(Routes.PROFIL_NOTIFICATIONS, { type: NotificationTypeEnum.Like, page: "1" })}
                className={"nav-link active text-" + (type === NotificationTypeEnum.Like ? "primary" : "ternary border-0")}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
              </NavLink>
              {notificationsNumber && notificationsNumber.types[NotificationTypeEnum.Like] > 0 && (
                <span style={{ top: "10%", left: "80%", padding: "2px 5px" }} className="position-absolute translate-middle badge rounded-pill bg-danger z-1">
                  {notificationsNumber.types[NotificationTypeEnum.Like]}
                </span>
              )}
            </li>
            <li className="nav-item position-relative">
              <NavLink
                to={generatePath(Routes.PROFIL_NOTIFICATIONS, { type: NotificationTypeEnum.Comment, page: "1" })}
                className={"nav-link active text-" + (type === NotificationTypeEnum.Comment ? "primary" : "ternary border-0")}
              >
                <FontAwesomeIcon icon={faMessage} />
              </NavLink>
              {notificationsNumber && notificationsNumber.types[NotificationTypeEnum.Comment] > 0 && (
                <span style={{ top: "10%", left: "80%", padding: "2px 5px" }} className="position-absolute translate-middle badge rounded-pill bg-danger z-1">
                  {notificationsNumber.types[NotificationTypeEnum.Comment]}
                </span>
              )}
            </li>
            <li className="nav-item position-relative">
              <NavLink
                to={generatePath(Routes.PROFIL_NOTIFICATIONS, { type: NotificationTypeEnum.Message, page: "1" })}
                className={"nav-link active text-" + (type === NotificationTypeEnum.Message ? "primary" : "ternary border-0")}
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </NavLink>
              {notificationsNumber && notificationsNumber.types[NotificationTypeEnum.Message] > 0 && (
                <span style={{ top: "10%", left: "80%", padding: "2px 5px" }} className="position-absolute translate-middle badge rounded-pill bg-danger z-1">
                  {notificationsNumber.types[NotificationTypeEnum.Message]}
                </span>
              )}
            </li>
          </ul>
        </div>

        <div>
          {isLoading ? (
            <LinearProgress />
          ) : isError ? (
            <Alert variant={error.variant}>{error.message}</Alert>
          ) : data.notifications.length < 1 ? (
            <Alert variant="info">
              {page === "1" ? (
                <>Aucune notification</>
              ) : (
                <>
                  Nous n'avons trouvé aucune notification sur cette page{" "}
                  <Link className="alert-link" to={generatePath(Routes.PROFIL_NOTIFICATIONS, { type: type, page: "1" })}>
                    page n°1.
                  </Link>
                </>
              )}
            </Alert>
          ) : (
            <>
              <ul className="list-group">
                {data.notifications.map((notification, index) => (
                  <div key={notification.id.toString() + index.toString()}>
                    {notification.type === NotificationTypeEnum.Like && (
                      <p>
                        {notification.username} a aimé votre <Link to={generatePath(Routes.PROBLEM, { id: notification.id.toString() })}>{transFromDiscr(notification.p_discr)}</Link>{" "}
                        {dateAgo(notification.date).toLowerCase()}
                      </p>
                    )}
                    {notification.type === NotificationTypeEnum.Comment && (
                      <p>
                        {notification.username} a commenté votre <Link to={generatePath(Routes.PROBLEM, { id: notification.p_id.toString() })}>{transFromDiscr(notification.p_discr)}</Link>{" "}
                        {dateAgo(notification.date).toLowerCase()}
                      </p>
                    )}
                    {notification.type === NotificationTypeEnum.Message && <MessageNotification message={notification} />}
                    <hr />
                  </div>
                ))}
              </ul>
              {data.totalPage > 1 && <Pagination page={Number.parseInt(page)} nbrPages={data.totalPage} nbrPagesInARow={5} url={regexPathname?.[1]} />}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

type MessageNotificationProps = {
  message: NotificationMessage;
};

const MessageNotification = ({ message }: MessageNotificationProps) => {
  const { dateAgo, br, sanitize } = useUtils();

  const { openModal } = useModals();
  const [isMessagePartialHidden, setIsMessagePartialHidden] = useState(false);
  const messageContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageContent.current && messageContent.current.offsetHeight >= 150) {
      setIsMessagePartialHidden(true);
    }
  }, [messageContent]);

  return (
    <div>
      <h4>{message.title}</h4>
      <button className="btn btn-link p-0" onClick={() => openModal(ModalTypeEnum.Message, { title: message.title, content: message.content })}>
        Nouveau message de {message.username} {dateAgo(message.date).toLowerCase()}
      </button>
      <div ref={messageContent} className="mt-2 position-relative overflow-hidden" style={{ maxHeight: 150 }}>
        <div dangerouslySetInnerHTML={sanitize(br(message.content))}></div>
        {isMessagePartialHidden && (
          <div className="shadow-content z-1 position-absolute bottom-0 start-0 w-100 text-center bg-white" style={{ padding: "0px 0 15px 0px", boxShadow: "0px 0px 40px 40px white" }}>
            <button className="btn btn-sm btn-primary" onClick={() => openModal(ModalTypeEnum.Message, { title: message.title, content: message.content })}>
              Voir la suite ...
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsModal;
