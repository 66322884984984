import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { LinearProgress } from "@mui/material";
import { Routes } from "../../routes";

type ProtectedRouteProps = {
  needAdmin?: boolean;
  needSuperAdmin?: boolean;
};

export default function ProtectedRoute({ children, needAdmin = false, needSuperAdmin = false }: React.PropsWithChildren<ProtectedRouteProps>) {
  const { user, isInitialLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isInitialLoading && (!user || (needAdmin && !user.isAdmin) || (needSuperAdmin && !user.isSuperAdmin))) {
      const loginURL = new URL(Routes.APPS_LOGIN);
      loginURL.searchParams.append("redirect", window.location.href);
      window.location.replace(loginURL);
    }
  }, [user, isInitialLoading, needAdmin, needSuperAdmin, navigate, location.pathname]);

  let canConnect: boolean = !!user;
  if (needAdmin) canConnect = user?.isAdmin ?? false;
  if (needSuperAdmin) canConnect = user?.isSuperAdmin ?? false;

  return canConnect ? children : <LinearProgress className="m-auto" style={{ maxWidth: 960 }} />;
}
