import { NavLink, Outlet } from "react-router-dom";
import { Routes } from "../../routes";
import { useAPI } from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { AdminData, AdminDataKey } from "./query";

function AdminLayout() {
  const api = useAPI();
  const { data: adminData } = useQuery<AdminData>(AdminDataKey, api.admin.adminData);

  return (
    <div className="d-flex justify-content-around flex-xxl-row flex-column">
      <ul className="nav nav-pills flex-xxl-column flex-row mx-auto mb-3" style={{ minWidth: 250 }}>
        <li className="nav-item">
          <NavLink to={Routes.ADMIN} className="nav-link" end>
            Statistiques
          </NavLink>
        </li>
        <li>
          <NavLink to={Routes.ADMIN_REVIEW} className="nav-link">
            Vérifier les problèmes
            {adminData ? <span className="badge text-bg-danger ms-3">{adminData.nbrProblemNotReviewed}</span> : <FontAwesomeIcon className="ms-3" icon={faSpinner} spin />}
          </NavLink>
        </li>
        <li>
          <NavLink to={Routes.ADMIN_MAIL} className="nav-link">
            Envoyer un courriel
          </NavLink>
        </li>
        <li>
          <NavLink to={Routes.ADMIN_MESSAGE} className="nav-link">
            Poster un message
          </NavLink>
        </li>
        <li>
          <NavLink to={Routes.ADMIN_CSV} className="nav-link">
            Exporter un csv
          </NavLink>
        </li>
        <li>
          <NavLink to={Routes.ADMIN_AI} className="nav-link">
            Gérer l'ia
          </NavLink>
        </li>
        <li>
          <p className="ps-3 mt-2">
            <span className="fw-bold">{adminData?.numberOfVisit}</span> visites
          </p>
        </li>
      </ul>
      <div className="w-100 mx-auto" style={{ maxWidth: 960 }}>
        <div className="w-100">
          <Outlet />
        </div>
      </div>
      <div className="mx-auto" style={{ minWidth: 250 }}></div>
    </div>
  );
}

export default AdminLayout;
