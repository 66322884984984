import axios from "axios";
import { getGetAxiosInstance, getPostPutAxiosInstance, handleError } from "./utils";

class MathWeekAPI {
  async counter() {
    try {
      const response = await getGetAxiosInstance().get("/math-week/counter");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async photos() {
    try {
      const response = await getGetAxiosInstance().get("/math-week/photos");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }

  async add(data: any) {
    try {
      const response = await getPostPutAxiosInstance().post("/math-week/add", data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleError(error);
      }
    }
  }
}

export default MathWeekAPI;
