import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiError, useAPI } from "../../hooks";
import { LinearProgress } from "@mui/material";
import { PhotoForm, PhotoProblemForm, TextProblemForm } from "../../components";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ProblemFormTypeEnum, ProblemTypeEnum } from "../../Utils";
import { FormErrors, MutationData, ProblemFormValue } from "../../types";
import { AdminGenerateAIData, AdminReviewProblem, AdminReviewProblemIndexKey, AdminReviewProblemKey, useAdminQuery } from "./query";
import { Alert } from "react-bootstrap";
import { ProblemIdKey, ProblemsTypeKey } from "../query";

type MutationVariables = {
  data?: ProblemFormValue;
  remove?: boolean;
};

function AdminReview() {
  const api = useAPI();
  const [formErrors, setFormErrors] = useState<FormErrors<ProblemFormValue>>([]);
  const queryClient = useQueryClient();
  const [index, setIndex] = useState(0);
  const { updateNumberProblmesToReview } = useAdminQuery();

  const {
    isLoading,
    isError,
    error,
    data: dataReview,
    isSuccess,
  } = useQuery<AdminReviewProblem, ApiError>(AdminReviewProblemIndexKey(index), () => api.admin.getReviewProblem(index), { cacheTime: 0 });

  const [problemsGettingResponseCalculated, setProblemsGettingResponseCalculated] = useState<number[]>([]);

  const responseAiMutation = useMutation<MutationData & AdminGenerateAIData, ApiError, number>({
    mutationFn: (data) => {
      return api.admin.generateAI(data);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      updateNumberProblmesToReview(dataReview.nbrProblemNotReviewed);
    }
  }, [dataReview, isSuccess, updateNumberProblmesToReview]);

  const reviewProblem = useMutation<MutationData, ApiError<ProblemFormValue>, MutationVariables>({
    mutationFn: ({ data, remove = false }) => {
      if (dataReview?.problem) return api.admin.review(dataReview.problem.id, data, dataReview.problem.isPrivateWait, remove);

      throw new Error("Error is contribute mutation");
    },
    onSuccess: () => {
      if (dataReview?.problem && dataReview.problem.discr === ProblemTypeEnum.TextProblem) {
        const problemId = dataReview.problem.id;
        const problemDiscr = dataReview.problem.discr;
        responseAiMutation
          .mutateAsync(problemId)
          .catch((error) => {})
          .finally(() => {
            queryClient.invalidateQueries(ProblemsTypeKey(problemDiscr));
            queryClient.invalidateQueries(ProblemIdKey(problemId));
            setProblemsGettingResponseCalculated((old) => old.filter((number) => number !== problemId));
          });
        setProblemsGettingResponseCalculated((old) => [...old, problemId]);
      }
      queryClient.resetQueries(AdminReviewProblemKey);
    },
    onError: (data) => {
      const errors = data.formErrors;
      if (errors.length === 0) {
        errors.push({ name: "root", message: data.message });
      }
      setFormErrors(errors);
    },
  });

  const onSubmit = (data: ProblemFormValue) => {
    if ("attachment" in data) {
      data.attachment = [];
    }
    reviewProblem.mutate({ data: data });
  };

  const handleCancel = () => {
    reviewProblem.mutate({ remove: true });
  };

  const handleClickNext = () => {
    setIndex((index) => index + 1);
  };

  const handleClickPrev = () => {
    setIndex((index) => index - 1);
  };

  return (
    <div>
      {isLoading ? <LinearProgress className="mb-3" /> : isError ? <Alert variant="danger">{error.message}</Alert> : null}

      {dataReview?.message && <Alert variant="success">{dataReview.message}</Alert>}
      {reviewProblem.isSuccess && <Alert variant="success">{reviewProblem.data.message}</Alert>}
      <div className="position-relative">
        <div className="float-start position-absolute top-0 start-0 align-middle">
          {dataReview?.prev && (
            <button className="btn text-primary" onClick={handleClickPrev}>
              <FontAwesomeIcon icon={faAngleLeft} size="4x" />
            </button>
          )}
        </div>
        <div>
          {dataReview && dataReview.problem?.discr === ProblemTypeEnum.TextProblem && (
            <TextProblemForm
              onFormSubmit={onSubmit}
              formErrors={formErrors}
              handleCancel={handleCancel}
              isLoading={reviewProblem.isLoading}
              problem={dataReview.problem}
              titleLabel="Valider"
              cancelLabel={dataReview.problem.isPrivateWait ? "Refuser" : "Supprimer"}
            />
          )}
          {dataReview && dataReview.problem?.discr === ProblemTypeEnum.Photo && (
            <PhotoForm
              onFormSubmit={onSubmit}
              formErrors={formErrors}
              handleCancel={handleCancel}
              isLoading={reviewProblem.isLoading}
              problem={dataReview.problem}
              titleLabel="Valider"
              cancelLabel="Supprimer"
              formType={ProblemFormTypeEnum.Review}
            />
          )}
          {dataReview && dataReview.problem?.discr === ProblemTypeEnum.PhotoProblem && (
            <PhotoProblemForm
              onFormSubmit={onSubmit}
              formErrors={formErrors}
              handleCancel={handleCancel}
              isLoading={reviewProblem.isLoading}
              problem={dataReview.problem}
              titleLabel="Valider"
              cancelLabel={dataReview.problem.isPrivateWait ? "Refuser" : "Supprimer"}
              formType={ProblemFormTypeEnum.Review}
            />
          )}
        </div>
        <div className="float-end position-absolute top-0 end-0">
          {dataReview?.next && (
            <button className="btn text-primary" onClick={handleClickNext}>
              <FontAwesomeIcon icon={faAngleRight} size="4x" />
            </button>
          )}
        </div>
      </div>
      <div className="fs-1 fw-bold position-fixed bottom-0 end-0 bg-secondary text-white px-3 rounded-3 m-2">{problemsGettingResponseCalculated.length}</div>
    </div>
  );
}

export default AdminReview;
