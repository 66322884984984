/**
 * All available routes for the app
 */

const Routes = {
  HOME: "/",

  PROBLEM: "/problem/:id",

  CONSULT: "/consult/:type/:page?",

  CONSULT_ENIGMA: "/consult/enigma/:page?",

  EDITION: "/edition/:type/:page?",

  CONTRIBUTE: "/add/:type",

  HELP: "/help",
  HELP_TEXT_PROBLEM: "/help/text-problem",
  HELP_PHOTO: "/help/photo",
  HELP_PHOTO_PROBLEM: "/help/photo-problem",
  HELP_ENIGMA: "/help/enigma",

  PROFIL: "/profil",
  PROFIL_ACTIVITY: "/profil/activity",
  PROFIL_GRADE: "/profil/grade",
  PROFIL_PARAMETERS: "/profil/parameters",
  PROFIL_NOTIFICATIONS: "/profil/notifications/:type/:page?",

  ADMIN: "/admin",
  ADMIN_REVIEW: "/admin/review",
  ADMIN_MAIL: "/admin/mail",
  ADMIN_MESSAGE: "/admin/message",
  ADMIN_CSV: "/admin/csv",
  ADMIN_AI: "/admin/ai",

  CONSOLE: "/console",

  MATH_WEEK: "/semaine-des-maths",

  // Others url
  APP_ATELIER: process.env.REACT_APP_ATELIER_URL as string,

  APPS: process.env.REACT_APP_APPS_URL as string,
  APPS_LOGIN: process.env.REACT_APP_APPS_URL + "/login",
  APPS_REGISTER: process.env.REACT_APP_APPS_URL + "/register",

  APPS_PROFIL_PARAMETERS: process.env.REACT_APP_APPS_URL + "/profil/parameters",

  APPS_LEGAL_NOTICES: process.env.REACT_APP_APPS_URL + "/mentions-legales",
  APPS_PRIVACY: process.env.REACT_APP_APPS_URL + "/privacy",
  APPS_CGU: process.env.REACT_APP_APPS_URL + "/cgu",
  APPS_CONTACT: process.env.REACT_APP_APPS_URL + "/contact",
  APPS_CREDITS: process.env.REACT_APP_APPS_URL + "/credits",

  // Redirections urls
  OLD_HOME: "/public",
} as const;

const Titles = {
  HELP: "Aide relative aux fonctionnalités de la banque collaborative",
  HELP_TEXT_PROBLEM: "Aide relative aux fonctionnalités de la banque de problèmes",
  HELP_PHOTO: "Aide relative aux fonctionnalités de la banque de photos mathématiques",
  HELP_PHOTO_PROBLEM: "Aide relative aux fonctionnalités de la banque de photo-problèmes",
  HELP_ENIGMA: "Aide relative aux fonctionnalités de la banque d'énigmes mathématiques",

  PROFIL: "Profil",
  PROFIL_ACTIVITY: "Profil - Activité",
  PROFIL_GRADE: "Profil - Grade",
  PROFIL_PARAMETERS: "Profil - Paramètres",
  PROFIL_NOTIFICATIONS: "Profil - Notifications",

  ADMIN: "Admin - Statistiques",
  ADMIN_REVIEW: "Admin - Validation",
  ADMIN_MAIL: "Admin - Email",
  ADMIN_MESSAGE: "Admin - Message",
  ADMIN_CSV: "Admin - Export CSV",
  ADMIN_AI: "Admin - IA",

  CONSOLE: "Console",

  MATH_WEEK: "Semaine des mathématiques 2025",
} as const;

const Descriptions = {} as const;

const NoIndex: (keyof typeof Routes)[] = [
  "PROBLEM",
  "CONSULT",
  "CONSULT_ENIGMA",
  "EDITION",
  "CONTRIBUTE",
  "PROFIL",
  "PROFIL_ACTIVITY",
  "PROFIL_GRADE",
  "PROFIL_PARAMETERS",
  "PROFIL_NOTIFICATIONS",
  "ADMIN",
  "ADMIN_REVIEW",
  "ADMIN_MAIL",
  "ADMIN_MESSAGE",
  "ADMIN_CSV",
  "ADMIN_AI",
  "CONSOLE",
  "MATH_WEEK",
  "OLD_HOME",
];

export { Routes, Titles, Descriptions, NoIndex };
