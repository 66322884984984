import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { ApiError, useAPI } from "../../hooks";
import { PhotoForm, PhotoProblemForm, TextProblemForm } from "..";
import { ProblemFormTypeEnum, ProblemTypeEnum } from "../../Utils";
import { FormErrors, ModalPropsDefault, MutationData, ProblemFormValue, ProblemType } from "../../types";
import { ProblemIdKey, ProblemsTypeKey } from "../../pages/query";
import { Alert, Modal } from "react-bootstrap";

type ProblemModifyModalProps = {
  problem: ProblemType;
  onSuccessValidation?: () => void;
};

function ProblemModifyModal({ show, closeModal, problem, onSuccessValidation }: ModalPropsDefault & ProblemModifyModalProps) {
  const api = useAPI();
  const queryClient = useQueryClient();
  const [formErrors, setFormErrors] = useState<FormErrors<ProblemFormValue>>([]);

  const handleClose = () => {
    setTimeout(() => {
      modifyProblem.reset();
      setFormErrors([]);
    }, 200);

    closeModal();
  };

  const modifyProblem = useMutation<MutationData, ApiError<ProblemFormValue>, ProblemFormValue>({
    mutationFn: (data) => {
      return api.problem.modify(problem.id, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(ProblemsTypeKey(problem.discr));
      queryClient.invalidateQueries(ProblemIdKey(problem.id));
      if (onSuccessValidation) {
        handleClose();
        onSuccessValidation();
      } else {
        setTimeout(() => {
          handleClose();
        }, 2000);
      }
    },
    onError: (data) => {
      const errors = data.formErrors;
      if (errors.length === 0) {
        errors.push({ name: "root", message: data.message });
      }
      setFormErrors(errors);
    },
  });

  const onSubmit = (data: ProblemFormValue) => {
    if ("attachment" in data) {
      data.attachment = [];
    }
    modifyProblem.mutate(data);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Body>
        {modifyProblem.isSuccess && <Alert variant="success">{modifyProblem.data.message}</Alert>}
        {!modifyProblem.isSuccess && (
          <>
            {problem.discr === ProblemTypeEnum.TextProblem && (
              <TextProblemForm
                onFormSubmit={onSubmit}
                formErrors={formErrors}
                isLoading={modifyProblem.isLoading}
                isSuccess={modifyProblem.isSuccess}
                handleCancel={handleClose}
                problem={problem}
                titleLabel="Modifier"
              />
            )}
            {problem.discr === ProblemTypeEnum.Photo && (
              <PhotoForm
                onFormSubmit={onSubmit}
                formErrors={formErrors}
                isLoading={modifyProblem.isLoading}
                isSuccess={modifyProblem.isSuccess}
                handleCancel={handleClose}
                problem={problem}
                titleLabel="Modifier"
                formType={ProblemFormTypeEnum.Modify}
              />
            )}
            {problem.discr === ProblemTypeEnum.PhotoProblem && (
              <PhotoProblemForm
                onFormSubmit={onSubmit}
                formErrors={formErrors}
                isLoading={modifyProblem.isLoading}
                isSuccess={modifyProblem.isSuccess}
                handleCancel={handleClose}
                problem={problem}
                titleLabel="Modifier"
                formType={ProblemFormTypeEnum.Modify}
              />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ProblemModifyModal;
