import { useQuery } from "@tanstack/react-query";
import { ApiError, useAPI } from "../../hooks";
import { LinearProgress } from "@mui/material";
import { ProfilGradeData, ProfilGradeKey } from "./query";
import { Alert } from "react-bootstrap";

type GradeBarProps = {
  actual: number;
  actualValidate: number;
  min: number;
  max: number;
};

const GradeBar = ({ actual, actualValidate, min, max }: GradeBarProps) => {
  const percentActual = ((actual - min) / (max + 1 - min)) * 100;
  const percentActualValidate = ((actualValidate - min) / (max + 1 - min)) * 100;

  return (
    <>
      <span className="position-absolute z-1 top-0" style={{ left: 5 }}>
        {min}
      </span>
      {actual > min && (
        <div className="h-100" style={{ width: percentActual > 100 ? "100%" : percentActual + "%" }}>
          <div className="bg-secondary grade-progress-bar h-100" style={{ borderRadius: 5 }}></div>
        </div>
      )}
      {actualValidate > min && (
        <div className="h-100 position-absolute top-0 start-0" style={{ width: percentActualValidate > 100 ? "100%" : percentActualValidate + "%" }}>
          <div className="grade-progress-bar h-100" style={{ borderRadius: 5, backgroundColor: "green" }}></div>
        </div>
      )}
      <span className="position-absolute z-1 top-0" style={{ right: 5 }}>
        {max === 99 ? "100 +" : max + 1}
      </span>
    </>
  );
};

function ProfilGrade() {
  const api = useAPI();
  const {
    data: gradeData,
    isLoading,
    isError,
    error,
  } = useQuery<ProfilGradeData, ApiError>(ProfilGradeKey, api.profil.gradeData, {
    staleTime: 30 * 1000, // 30 sec
  });

  return (
    <div className="row">
      <h2 className="mb-3">Liste des grades</h2>
      {isLoading ? (
        <LinearProgress />
      ) : isError ? (
        <Alert variant={error.variant}>{error.message}</Alert>
      ) : (
        <>
          <div className="col-12 col-lg-6">
            {gradeData.gradeProgressBar.step.map((item, index) => (
              <div key={index}>
                <p>
                  Grade {index + 1} - Contributeur <span className="fw-bold">"{item[2]}"</span>
                </p>
                <div className="text-white fw-bold mb-3 bg-ternary w-100 position-relative" style={{ borderRadius: 5, height: 25 }}>
                  <GradeBar actual={gradeData.gradeProgressBar.actual} actualValidate={gradeData.gradeProgressBar.actualValidate} min={item[0]} max={item[1]} />
                </div>
              </div>
            ))}
          </div>
          <div className="col-12 col-lg-6">
            {gradeData.isCertifie ? (
              <div className="text-center">
                <a href={gradeData.urlBadge} rel="noreferrer" target="_blank">
                  <img style={{ width: "80%" }} src={gradeData.urlBadge} alt="Badge contributeur" />
                </a>
              </div>
            ) : (
              <div className="text-center">
                <img style={{ width: "80%", opacity: 0.4 }} src="img/contributeurBadgeGrey.webp" alt="Badge contributeur gris" />
              </div>
            )}
          </div>
          <p>
            Lorsque vous obtenez le grade <span className="fw-bold">Certifié</span> vous pouvez télécharger votre badge contributeur
          </p>
        </>
      )}
    </div>
  );
}

export default ProfilGrade;
