import { faAngleDoubleLeft, faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useAuth } from "../contexts/auth";
import { Dispatch, SetStateAction, useEffect } from "react";
import { PhotoTypeEnum, ProblemTypeEnum } from "../Utils";
import { generatePath, useNavigate } from "react-router-dom";
import { Routes } from "../routes";
import { CheckboxArray } from "./Checkbox";
import { PhotoProblemConsultFormValues } from "../types";

type PhotoProblemConsultFormProps = {
  isEdition: boolean;
  setFilters: (filters: PhotoProblemConsultFormValues) => void;
  setShowFilter: Dispatch<SetStateAction<boolean>>;
  filters: PhotoProblemConsultFormValues;
};

function PhotoProblemConsultForm({ isEdition, setFilters, setShowFilter, filters }: PhotoProblemConsultFormProps) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: filters,
  });

  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (filters !== null) {
      reset(filters, { keepDefaultValues: true });
    }
  }, [filters, reset]);

  const onSubmit = (data: PhotoProblemConsultFormValues) => {
    if (!data["tag"].length && user) {
      data["tag"] = ["COMMUNITY_PRIVATE", "COMMUNITY_PUBLIC"];
    }

    setFilters(data);
    setShowFilter(false);
    navigate(generatePath(isEdition ? Routes.EDITION : Routes.CONSULT, { type: ProblemTypeEnum.PhotoProblem, page: "1" }), { replace: true });
  };

  return (
    <div className="form-template form-consult-problem">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="label-form position-relative">
          <h1 className="h3 text-center">Consulter les photo-problèmes</h1>
          <button type="button" style={{ right: 25, top: "25%" }} className="btn btn-primary position-absolute" aria-label="Close" onClick={() => setShowFilter(false)}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </button>
        </div>
        <div className="alert-form"></div>
        <div className="body-form">
          <div className="mb-3">
            <input type="text" {...register("textResearch")} className="form-control" placeholder="Rechercher dans l'énoncé" required autoFocus />
          </div>
          <div className="d-flex justify-content-evenly">
            <div className="form-check">
              <input type="checkbox" id="filter_tag_0" {...register("tag")} className="form-check-input" value="COMMUNITY_PRIVATE" disabled={!user} />
              <label className="form-check-label" htmlFor="filter_tag_0">
                Banque personnelle
              </label>
            </div>
            <div className="form-check">
              <input type="checkbox" id="filter_tag_1" {...register("tag")} className="form-check-input" value="COMMUNITY_PUBLIC" disabled={!user} />
              <label className="form-check-label" htmlFor="filter_tag_1">
                Banque collaborative
              </label>
            </div>
          </div>

          <hr />

          <div className="d-flex justify-content-between flex-column flex-sm-row">
            <div>
              <p className="fw-bold mb-2 ms-2">Espace et géométrie</p>
              <CheckboxArray name="type" register={register} value="geom_1" label={PhotoTypeEnum.geom_1} />
              <CheckboxArray name="type" register={register} value="geom_2" label={PhotoTypeEnum.geom_2} />
              <CheckboxArray name="type" register={register} value="geom_3" label={PhotoTypeEnum.geom_3} />
              <CheckboxArray name="type" register={register} value="geom_4" label={PhotoTypeEnum.geom_4} />
              <CheckboxArray name="type" register={register} value="geom_5" label={PhotoTypeEnum.geom_5} />
              <CheckboxArray name="type" register={register} value="geom_6" label={PhotoTypeEnum.geom_6} />
              <CheckboxArray name="type" register={register} value="geom_7" label={PhotoTypeEnum.geom_7} />
              <CheckboxArray name="type" register={register} value="geom_8" label={PhotoTypeEnum.geom_8} />
              <CheckboxArray name="type" register={register} value="geom_9" label={PhotoTypeEnum.geom_9} />
            </div>
            <div>
              <p className="fw-bold mb-2 ms-2">Grandeurs et mesures</p>
              <CheckboxArray name="type" register={register} value="meas_1" label={PhotoTypeEnum.meas_1} />
              <CheckboxArray name="type" register={register} value="meas_2" label={PhotoTypeEnum.meas_2} />
              <CheckboxArray name="type" register={register} value="meas_3" label={PhotoTypeEnum.meas_3} />
              <CheckboxArray name="type" register={register} value="meas_4" label={PhotoTypeEnum.meas_4} />
              <CheckboxArray name="type" register={register} value="meas_5" label={PhotoTypeEnum.meas_5} />
              <CheckboxArray name="type" register={register} value="meas_6" label={PhotoTypeEnum.meas_6} />
              <CheckboxArray name="type" register={register} value="meas_7" label={PhotoTypeEnum.meas_7} />
              <CheckboxArray name="type" register={register} value="meas_8" label={PhotoTypeEnum.meas_8} />
            </div>
            <div>
              <p className="fw-bold mb-2 ms-2">Nombres et calculs</p>
              <CheckboxArray name="type" register={register} value="numb_1" label={PhotoTypeEnum.numb_1} />
              <CheckboxArray name="type" register={register} value="numb_2" label={PhotoTypeEnum.numb_2} />
              <CheckboxArray name="type" register={register} value="numb_3" label={PhotoTypeEnum.numb_3} />
              <CheckboxArray name="type" register={register} value="numb_4" label={PhotoTypeEnum.numb_4} />
              <CheckboxArray name="type" register={register} value="numb_5" label={PhotoTypeEnum.numb_5} />
              <CheckboxArray name="type" register={register} value="numb_6" label={PhotoTypeEnum.numb_6} />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-center">
            <CheckboxArray name="nivel" register={register} value="c1" label="Cycle 1" className="ms-1 me-2" />
            <CheckboxArray name="nivel" register={register} value="cp" label="CP" className="ms-1 me-2" />
            <CheckboxArray name="nivel" register={register} value="ce1" label="CE1" className="ms-1 me-2" />
            <CheckboxArray name="nivel" register={register} value="ce2" label="CE2" className="ms-1 me-2" />
            <CheckboxArray name="nivel" register={register} value="cm1" label="CM1" className="ms-1 me-2" />
            <CheckboxArray name="nivel" register={register} value="cm2" label="CM2" className="ms-1 me-2" />
            <CheckboxArray name="nivel" register={register} value="6eme" label="Sixième" className="ms-1 me-2" />
          </div>
          <div className="text-center">
            <button className="btn btn-primary mt-2" type="submit">
              <FontAwesomeIcon icon={faFilter} /> Filtrer
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PhotoProblemConsultForm;
