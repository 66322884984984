import { matchPath, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthProvider } from "../contexts/auth";
import { ScrollToAnchor } from ".";
import "react-toastify/dist/ReactToastify.css";
import { NotificationsProvider } from "../contexts/notifications";
import { ModalsProvider } from "../contexts/modals";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { getPostPutAxiosInstance } from "../hooks/useAPI/api/utils";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Descriptions, NoIndex, Routes, Titles } from "../routes";

declare global {
  interface Window {
    _mtm: any;
  }
}

function Fallback() {
  const navigate = useNavigate();
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className="mx-auto text-center" style={{ maxWidth: 960, marginTop: "50vh", transform: "translateY(-50%)" }}>
      <img src="img/logo_small.webp" alt="M@ths en-vie" width="180" height="180" />
      <p className="mt-3 lead fw-bold">Une erreur est survenue, vous pouvez appuyer sur le bouton ci-dessous pour tenter de recharger la page.</p>
      <button className="btn btn-primary mt-3" onClick={() => window.location.reload()}>
        Recharger la page
      </button>
      <button
        className="btn btn-ternary mt-3 ms-5"
        onClick={() => {
          resetBoundary();
          navigate(Routes.HOME);
        }}
      >
        Retourner à la page d'acceuil
      </button>
      <p className="mt-3">
        Si l'erreur persiste vous pouvez{" "}
        <button
          className="btn btn-link p-0"
          onClick={() => {
            resetBoundary();
            navigate(Routes.APPS_CONTACT);
          }}
        >
          nous contacter
        </button>{" "}
        en expliquant comment vous avez rencontré cette erreur.
      </p>
      <p className="small mt-3 fst-italic">Nous avons également à l'instant reçu une notification sur cette erreur et nous travaillons actuellement pour la résoudre.</p>
    </div>
  );
}

const logError = (error: Error, info: React.ErrorInfo) => {
  try {
    getPostPutAxiosInstance()
      .post("/error-report", { error: error.message, stack: info.componentStack })
      .catch(() => {});
  } catch {}
};

function ScrollToTop() {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);
  }, [pathname]);

  return null;
}

const BaseLayout = () => {
  // useEffect(() => {
  //     var _mtm = (window._mtm = window._mtm || []);
  //     _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
  //     var d = document,
  //         g = d.createElement("script"),
  //         s: any = d.getElementsByTagName("script")[0];
  //     g.async = true;
  //     g.src = "http://localhost/analytics/js/container_hWW4iAPe.js";
  //     s.parentNode.insertBefore(g, s);
  // }, []);
  const { pathname } = useLocation();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [noIndex, setNoIndex] = useState(false);

  useEffect(() => {
    setTitle("Banque de problèmes, photos et énigmes mathématiques du CP à la sixième");
    setDescription("Une banque collaborative de problèmes, photos mathématiques et photo-problèmes avec possiblité d'édition et de vidéo-projection - M@ths en-vie.");
    setNoIndex(false);

    for (const [route, path] of Object.entries(Routes)) {
      if (matchPath(path, pathname)) {
        if (route in Titles) {
          setTitle(Titles[route as keyof typeof Titles]);
        }
        if (route in Descriptions) {
          setDescription(Descriptions[route as keyof typeof Descriptions]);
        }
        if (NoIndex.includes(route as keyof typeof Routes)) {
          setNoIndex(true);
        }
        break;
      }
    }
  }, [pathname]);

  return (
    <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
      <AuthProvider>
        <NotificationsProvider>
          <ModalsProvider>
            <Helmet>
              <title>{title}</title>
              <meta property="og:title" content={title} />
              <meta property="og:description" content={description} />
              <meta name="description" content={description} />
              {noIndex && <meta name="robots" content="noindex" />}
            </Helmet>
            <Outlet />
            <ScrollToTop />
            <ScrollToAnchor />
          </ModalsProvider>
        </NotificationsProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default BaseLayout;
