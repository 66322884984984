import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../hooks";
import { toast } from "react-toastify";
import { Routes } from "../routes";

type ContextData = {
  user: UserData | undefined | null;
  logout: () => void;
  setIsSubscribedEmails: (isSubscribed: boolean) => void;
  isInitialLoading: boolean;
};
const AuthContext = createContext<ContextData | undefined>(undefined);

const UserDataKey: [base: string] = ["user"];

export type UserData = {
  username: string;
  email: string;
  grade: number;
  isEmailsSubscribed: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
};

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const api = useAPI();
  const queryClient = useQueryClient();

  const [user, setUser] = useState<UserData | null | undefined>(null);

  const { data: _user, isInitialLoading } = useQuery<UserData>(UserDataKey, api.user.getUserData, {
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (!isInitialLoading) {
      setUser(_user);
    }
  }, [_user, isInitialLoading]);

  // call this function to logged out in user
  const logout = useCallback(() => {
    api.user
      .logout()
      .then(() => {
        window.location.replace(Routes.APPS);
        queryClient.removeQueries();
        setUser(undefined);
      })
      .catch(() => {
        toast.error("Erreur lors de la déconnexion, veuillez contacter un administrateur.", { toastId: "logout" });
      });
  }, [api.user, queryClient]);

  const setIsSubscribedEmails = (isSubscribed: boolean) => {
    setUser((old) => (old ? { ...old, isEmailsSubscribed: isSubscribed } : undefined));
  };

  const value = useMemo(() => ({ user: user && Object.keys(user).length === 0 ? undefined : user, logout, setIsSubscribedEmails, isInitialLoading: user === null }), [logout, user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const object = useContext(AuthContext);
  if (!object) {
    throw new Error("useAuth must be used within a Provider");
  }
  return object;
};

export { AuthProvider, useAuth };
