import { faBookOpen, faFilePdf, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useAPI } from "../hooks";
import { useQuery } from "@tanstack/react-query";
import { Link, generatePath } from "react-router-dom";
import { ProblemTypeEnum } from "../Utils";
import { ProblemCounterData, ProblemsCounterKey } from "../pages/query";
import { Routes } from "../routes";

type CounterProps = {
  number?: number;
};

const Counter = ({ number }: CounterProps) => {
  if (number === undefined) {
    return;
  }

  const nbr = String(number);

  return (
    <span>
      {Array.from(nbr).map((digit, index) => (
        <span key={index} className="bg-dark text-white d-inline-block px-1 rounded-3" style={{ marginLeft: 1, marginRight: 1, animation: "problemCounter 0.3s linear " + (index + 1) }}>
          {digit}
        </span>
      ))}
    </span>
  );
};

function Carousel() {
  const [itemSelected, setItemSelected] = useState<Array<"selected" | "next" | "prev">>(["selected", "next", "prev"]);
  const api = useAPI();

  const { data: problemCounter } = useQuery<ProblemCounterData>(ProblemsCounterKey, api.problem.problemCounter, {
    refetchOnReconnect: false,
  });

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const element = e.currentTarget;

    if (element.matches(".selected")) {
      return;
    }

    const array = [...itemSelected];
    if (element.matches(".next")) {
      const popElement = array.pop();
      if (popElement) array.unshift(popElement);
    }
    if (element.matches(".prev")) {
      const pushElement = array.shift();
      if (pushElement) array.push(pushElement);
    }
    setItemSelected(array);
  };

  return (
    <div id="carousel" className="mt-3">
      <div className={"d-flex flex-column " + itemSelected[0]} onClick={handleClick}>
        <div className="bg-secondary p-3 m-1 d-table">
          <h2 className="fw-semibold d-table-cell align-middle">
            <Counter number={problemCounter?.textProblem} /> Problèmes
          </h2>
        </div>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.CONSULT, { type: ProblemTypeEnum.TextProblem, page: "1" })}>
          <FontAwesomeIcon icon={faBookOpen} size="4x" />
          <h3 className="mt-2 fw-semibold">Consulter</h3>
          <p>Consultez les problèmes classés selon notre typologie.</p>
        </Link>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.CONTRIBUTE, { type: ProblemTypeEnum.TextProblem })}>
          <FontAwesomeIcon icon={faPen} size="4x" />
          <h3 className="mt-2 fw-semibold">Contribuer</h3>
          <p>Partagez vos problèmes avec la communauté.</p>
        </Link>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.EDITION, { type: ProblemTypeEnum.TextProblem, page: "1" })}>
          <FontAwesomeIcon icon={faFilePdf} size="4x" className="align-top" />
          <img className="d-inline-block ms-4" alt="Projecteur Icone" src="img/whiteProjector.webp" height="64" width="100" />
          <h3 className="mt-2 fw-semibold">Éditer - Vidéoprojeter</h3>
          <p>Éditez des fiches pour vos élèves ou projetez pour la classe.</p>
        </Link>
      </div>
      <div className={"d-flex flex-column " + itemSelected[1]} onClick={handleClick}>
        <div className="bg-secondary p-3 m-1 d-table">
          <h2 className="fw-semibold d-table-cell align-middle">
            <Counter number={problemCounter?.[ProblemTypeEnum.Photo]} /> Photos
          </h2>
        </div>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.CONSULT, { type: ProblemTypeEnum.Photo, page: "1" })}>
          <FontAwesomeIcon icon={faBookOpen} size="4x" />
          <h3 className="mt-2 fw-semibold">Consulter</h3>
          <p>Consultez les photos classées par thèmes.</p>
        </Link>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.CONTRIBUTE, { type: ProblemTypeEnum.Photo })}>
          <FontAwesomeIcon icon={faPen} size="4x" />
          <h3 className="mt-2 fw-semibold">Contribuer</h3>
          <p>Partagez vos photos avec la communauté.</p>
        </Link>
        <Link className="btn btn-secondary p-2 m-1 eurekamaths-bloc" to={generatePath(Routes.CONSULT_ENIGMA, { page: "1" })}>
          <h2 className="mt-2 mb-3 fw-semibold">
            <Counter number={problemCounter?.enigma} /> Énigmes
          </h2>
          <img className="d-inline-block" alt="Logo Eurêkamaths" src="img/eurekamathsLogo_small.webp" height="32" width="32" />
          <img className="d-inline-block mx-1" alt="Logo Epinay" src="img/epinaylogo_small.webp" height="32" width="32" />
          <img className="d-inline-block" alt="Logo Maths en-vie" src="img/logo_small.webp" height="32" width="32" />
        </Link>
      </div>
      <div className={"d-flex flex-column " + itemSelected[2]} onClick={handleClick}>
        <div className="bg-secondary p-3 m-1 d-table">
          <h2 className="fw-semibold d-table-cell align-middle">
            <Counter number={problemCounter?.[ProblemTypeEnum.PhotoProblem]} /> Photo-problèmes
          </h2>
        </div>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.CONSULT, { type: ProblemTypeEnum.PhotoProblem, page: "1" })}>
          <FontAwesomeIcon icon={faBookOpen} size="4x" />
          <h3 className="mt-2 fw-semibold">Consulter</h3>
          <p>Consultez les photo-problèmes classés selon notre typologie.</p>
        </Link>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.CONTRIBUTE, { type: ProblemTypeEnum.PhotoProblem })}>
          <FontAwesomeIcon icon={faPen} size="4x" />
          <h3 className="mt-2 fw-semibold">Contribuer</h3>
          <p>Partagez vos photo-problèmes avec la communauté.</p>
        </Link>
        <Link className="btn btn-primary p-2 m-1" to={generatePath(Routes.EDITION, { type: ProblemTypeEnum.PhotoProblem, page: "1" })}>
          <FontAwesomeIcon icon={faFilePdf} size="4x" className="align-top" />
          <img className="d-inline-block ms-4" alt="Projecteur Icone" src="img/whiteProjector.webp" height="32" width="50" />
          <h3 className="mt-2 fw-semibold">Éditer - Vidéoprojeter</h3>
          <p>Éditez des fiches pour vos élèves ou projetez pour la classe.</p>
        </Link>
      </div>
    </div>
  );
}

export default Carousel;
