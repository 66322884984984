import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function Help() {
  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1>Aide générale</h1>

      <p className="mt-3">Cette banque mathématiques a plusieurs objectifs :</p>
      <ul>
        <li>aider les enseignants à structurer leur enseignement de la résolution de problèmes ;</li>
        <li>fournir des photos catégorisées supports à des activités mathématiques ;</li>
        <li>fournir des problèmes et photo-problèmes classés selon une typologie ;</li>
        <li>fournir des outils d'édition et de vidéoprojection pour utiliser avec les élèves les contenus.</li>
      </ul>

      <p className="mt-3">Cette plateforme comporte quatre modules :</p>
      <ul>
        <li>
          une banque de problèmes en 5 langues (français, anglais, allemand, espagnol et italien), du CP à la sixième, avec un accès par filtres, un formulaire de contribution permettant de soumettre
          ses propres problèmes à la communauté et un outil d'édition permettant de générer des fiches élèves et vidéoprojeter des problèmes pour la classe ;
        </li>
        <li>une banque de photos avec un accès par filtres et un formulaire de contribution permettant de soumettre ses propres photos à la communauté ;</li>
        <li>une banque d'énigmes mathématiques pour le cycle 3 avec un accès par filtres ;</li>
        <li>
          une banque de photo-problèmes de la PS à la sixième avec un accès par filtres, un formulaire de contribution permettant de soumettre ses propres photo-problèmes à la communauté et un outil
          d'édition permettant de générer des fiches élèves et vidéoprojeter des photo-problèmes pour la classe.
        </li>
      </ul>

      <p className="mt-3">
        Cette banque est collaborative. Elle ne contient que des problèmes, photos et photo-problèmes proposés par ses utilisateurs et validés par un administrateur. Qu'il s'agisse des banques de
        problèmes, de photos ou de photo-problèmes, votre contribution à trois problèmes, trois photos ou trois photo-problèmes vous ouvrira l'accès à l'intégralité des contenus respectifs de chaque
        module.
      </p>

      <h2 className="mt-3">Sommaire</h2>

      <ul className="mt-3">
        <li className="mb-2">
          <Link to={{ hash: "roles" }}>Les rôles</Link>
          <ul>
            <li className="mb-2 mt-2">
              <Link to={{ hash: "simpleUser" }}>Simple visiteur</Link>
            </li>
            <li className="mb-2">
              <Link to={{ hash: "registeredUser" }}>Utilisateur enregistré</Link>
            </li>
            <li>
              <Link to={{ hash: "adherentUser" }}>Utilisateur adhérent</Link>
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <Link to={{ hash: "teach" }}>Enseigner</Link>
        </li>
        <li>
          <Link to={{ hash: "profil" }}>Votre profil</Link>
        </li>
      </ul>

      <hr />

      <h2 id="roles" className="mt-3">
        Les rôles
      </h2>

      <h3 id="simpleUser" className="mt-3 fs-4">
        Simple visiteur
      </h3>
      <p className="mt-3 fst-italic">Définition : un simple visiteur est une personne accédant au site sans authentification.</p>

      <p className="mt-3">Vous pouvez :</p>
      <ul>
        <li>consulter un extrait de chaque module de la banque collaborative (une sélection de quelques problèmes, photos et photo-problèmes) ;</li>
        <li>consulter l'intégralité de la banque d'énigmes mathématiques ;</li>
        <li>utiliser la recherche par filtres ;</li>
        <li>copier les énoncés et les photos accessibles pour les insérer dans vos documents.</li>
      </ul>

      <h3 id="registeredUser" className="mt-3 fs-4">
        Utilisateur enregistré
      </h3>
      <p className="mt-3 fst-italic">Définition : un utilisateur enregistré est une personne ayant créé un compte sur la plateforme.</p>

      <p className="mt-3">Vous pouvez en plus :</p>
      <ul>
        <li>saisir vos problèmes et photo-problèmes dans votre banque personnelle et les soumettre pour la banque collaborative via le bouton « Contribuer » ;</li>
        <li>soumettre vos problèmes, photos et photo-problèmes à la communauté. Ils seront validés par un administrateur ;</li>
        <li>consulter votre banque de problèmes personnelle et copier les énoncés de vos problèmes pour les insérer dans vos propres documents ;</li>
        <li>consulter votre banque de photo-problèmes personnelle ;</li>
        <li>
          consulter l'intégralité de la banque collaborative de :
          <ul>
            <li>problèmes et copier les énoncés pour les insérer dans vos propres documents, à condition d'avoir contribué à 3 problèmes ;</li>
            <li>de photos, à condition d'avoir contribué à 3 photos ;</li>
            <li>de photos-problèmes, à condition d'avoir contribué à 3 photos-problèmes ;</li>
          </ul>
        </li>
        <li>éditer des fiches pour vos élèves et vidéoprojeter des problèmes pour la classe, en puisant dans votre banque personnelle uniquement ;</li>
        <li>aimer et commenter les problèmes, les photos et les photo-problèmes à condition d'avoir contribué à 3 problèmes ou à 3 photos.</li>
      </ul>
      <p>À noter que l'édition des problèmes de la communauté et des photo-problèmes ainsi que la possibilité d'aimer et commenter sont réservées aux adhérents de l'association M@ths'n Co.</p>

      <h3 id="adherentUser" className="mt-3 fs-4">
        Utilisateur adhérent
      </h3>
      <p className="fst-italic">
        Définition : un visiteur adhérent est une personne ayant créé un compte sur la plateforme et ayant renseigné le code adhérent. Pour être adhérent, il faut être à jour de sa cotisation à
        l'association.
      </p>

      <p>
        M@ths'n Co. Pour en savoir plus,{" "}
        <a href="https://www.mathsenvie.fr/association" rel="noreferrer" target="_blank">
          cliquez ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>
      <p className="mt-3">Vous pouvez en plus:</p>
      <ul>
        <li>consulter toutes les banques collaboratives (sans condition de contribution) et copier les énoncés pour les insérer dans vos propres documents ;</li>
        <li>éditer des fiches de problèmes et photo-problèmes pour vos élèves ;</li>
        <li>vidéoprojeter des problèmes et photo-problèmes pour la classe en puisant dans la banque collaborative.</li>
      </ul>

      <hr />

      <h2 id="teach" className="mt-3">
        Enseigner
      </h2>
      <p>La résolution de problèmes, ça s'enseigne ! Suivez notre méthode clé en main reposant sur un enseignement explicite et structuré.</p>
      <p className="mt-3">La méthode pour le cycle 2 et le cycle 3 s'appuie :</p>
      <ul>
        <li>sur des photo-problèmes pour les séances d'apprentissages afin de créer un répertoire de problèmes auxquels l'élève pourra faire référence ;</li>
        <li>sur une progression, basée sur une typologie de problèmes claire et simplifiée, afin de développer des analogies et des automatismes chez les élèves ;</li>
        <li>sur un outil de modélisation simple et universel, support à la verbalisation ;</li>
        <li>sur de nombreux outils et adaptations permettant de favoriser l'inclusion, mettre en place des différenciations et développer l'autonomie des élèves ;</li>
        <li>
          sur des banques de problèmes riches et variées : problèmes à projeter ou à réaliser à l'oral, problèmes pour aller plus loin, affichages, problèmes de recherche, problèmes autonomes, plans
          de travail, fiches d'évaluation, outils de suivi...
        </li>
        <li>sur un outil numérique interactif de modélisation en barres.</li>
      </ul>

      <p className="mt-3">
        En savoir plus :{" "}
        <a href="https://www.mathsenvie.fr/?page_id=6962" rel="noreferrer" target="_blank">
          cliquer ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>
      <p className="mt-3">
        Pour acquérir la méthode{" "}
        <a href="https://www.generation5.fr/views/pages/mathsenvie.php" rel="noreferrer" target="_blank">
          cliquer ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>

      <hr />

      <h2 id="profil" className="mt-3">
        Votre profil
      </h2>
      <p className="mt-3">Chaque utilisateur dispose d'un profil dans lequel il peut :</p>
      <ul>
        <li>Modifier son avatar.</li>
        <li>
          Consulter dans « Activité » :
          <ul>
            <li>le nombre de problèmes créés ;</li>
            <li>le nombre de problèmes créés et publiés ;</li>
            <li>le nombre de photos publiées ;</li>
            <li>le nombre de photos-problèmes publiés ;</li>
            <li>le nombre de commentaire postés ;</li>
            <li>le nombre de problèmes, photos et photo-problèmes en attente de validation.</li>
          </ul>
        </li>
        <li>
          Consulter votre statut de contributeur :
          <ul>
            <li>Grade 1 - Contributeur "Novice"</li>
            <li>Grade 2 - Contributeur "Débutant"</li>
            <li>Grade 3 - Contributeur "Confirmé"</li>
            <li>Grade 4 - Contributeur" Expert"</li>
            <li>
              Grade 5 - Contributeur "Certifié". Ce grade vous permet d'obtenir le badge contributeur et l'inscription de votre nom dans la liste des lauréats :{" "}
              <a href="https://www.mathsenvie.fr/les-badges/" rel="noreferrer" target="_blank">
                https://www.mathsenvie.fr/les-badges <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
              </a>
            </li>
          </ul>
        </li>
        <li>Modifier dans ses paramètres son mot de passe et saisir son code adhérent ou code de la méthode afin de débloquer certains accès.</li>
        <li>
          Consulter ses notifications :
          <ul>
            <li>les problèmes « aimés » par la communauté ;</li>
            <li>les commentaires écrits par la communauté concernant ses problèmes ;</li>
            <li>les messages postés par l'administrateur.</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Help;
