import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faUser, faRightFromBracket, faTerminal, faUserShield, faGear, faSpinner, faLightbulb, faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../contexts/auth";
import { Routes } from "../routes";
import { useNotifications } from "../contexts/notifications";

type HeaderProps = {
  height: number;
};

export default function Header({ height }: Readonly<HeaderProps>) {
  const closeHeaderDropdown = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const dropDownMenu = e.currentTarget.parentElement?.parentElement;
    dropDownMenu?.classList.add("no-dropdown-hover");
    setTimeout(() => {
      dropDownMenu?.classList.remove("no-dropdown-hover");
    }, 1);
  };

  return (
    <header className="bg-ternary fixed-top" style={{ height: height }}>
      <div id="navbar">
        <nav className="navbar navbar-expand navbar-dark bg-ternary d-flex justify-content-between">
          <div className="d-flex justify-content-arround w-100" style={{ height: height }} id="navbarPrincipal">
            <ul className="navbar-nav me-auto">
              {/* <li className="nav-item">
                <a href="https://www.mathsenvie.fr/" className="d-flex h-100 p-2">
                  <img className="align-self-center" src="img/logo_small.webp" alt="Site M@ths en-vie" style={{ height: 30 }} />
                </a>
              </li> */}
              <li className="nav-item">
                <a href={Routes.APPS} className="d-flex btn btn-ternary text-primary rounded-0 h-100">
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faDoorOpen} />
                </a>
              </li>
              <li className="nav-item">
                <Link className="d-flex btn btn-ternary text-primary rounded-0 h-100" to={Routes.HOME}>
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faHouse} />
                </Link>
              </li>
            </ul>
            <div className="h-100 w-100 text-center justify-content-center d-none d-sm-flex">
              <div className="align-self-center d-lg-none d-flex flex-column me-2" style={{ height: "75%" }}>
                <img className="h-50" src="img/header_banque.webp" alt="Site M@ths en-vie" />
                <img className="h-50" src="img/header_problem.webp" alt="Site M@ths en-vie" />
              </div>
              <img className="h-100 d-lg-block d-none" src="img/header_banque.webp" alt="Site M@ths en-vie" />
              <img className="h-100 d-lg-block d-none mx-lg-3" src="img/header_problem.webp" alt="Site M@ths en-vie" />
              <img className="align-self-center" style={{ height: "100%" }} src="img/header_mathsenvie.webp" alt="Site M@ths en-vie" />
            </div>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <button className="d-flex btn btn-ternary text-primary rounded-0 h-100" data-bs-toggle="dropdown" aria-expanded="false" aria-label="Aides">
                  <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faLightbulb} />
                </button>
                <ul className="dropdown-menu pt-0 pb-0">
                  <li>
                    <NavLink to={Routes.HELP} className="dropdown-item" onClick={closeHeaderDropdown} end>
                      Aide Générale
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Routes.HELP_TEXT_PROBLEM} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Problèmes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Routes.HELP_PHOTO} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Photos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Routes.HELP_PHOTO_PROBLEM} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Photo-Problèmes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to={Routes.HELP_ENIGMA} className="dropdown-item" onClick={closeHeaderDropdown}>
                      Enigmes
                    </NavLink>
                  </li>
                </ul>
              </li>
              <UserHeader />
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

function UserHeader() {
  const { user, logout, isInitialLoading } = useAuth();
  const { notificationsNumber } = useNotifications();

  const loginURL = new URL(Routes.APPS_LOGIN);
  loginURL.searchParams.append("redirect", window.location.origin + (process.env.REACT_APP_BASENAME_ROUTER === "/" ? "" : process.env.REACT_APP_BASENAME_ROUTER) + Routes.HOME);

  return (
    <>
      {isInitialLoading && <FontAwesomeIcon icon={faSpinner} spin size="lg" className="text-white mx-3" style={{ marginTop: 15 }} />}
      {user && (
        <li className="nav-item dropdown">
          <button className="btn btn-ternary text-primary rounded-0 h-100" data-bs-toggle="dropdown" aria-expanded="false" aria-label="Profile">
            <div className="d-flex">
              <FontAwesomeIcon className="bg-secondary p-2 rounded-5 align-self-center" icon={faUser} />
            </div>
            <div className="position-relative">
              {notificationsNumber && notificationsNumber.total > 0 && (
                <span className="position-absolute translate-middle p-1 bg-danger border border-light rounded-circle" style={{ bottom: "20px", left: "76%" }}>
                  <span className="visually-hidden">New alerts</span>
                </span>
              )}
            </div>
          </button>
          <ul className="dropdown-menu dropdown-menu-end bg-ternary no-dropdown-hover">
            <p className="p-1 mb-0 text-center">{user.username}</p>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <NavLink className="dropdown-item" to={Routes.PROFIL_ACTIVITY}>
                <FontAwesomeIcon icon={faUser} /> Profil
                {notificationsNumber && notificationsNumber.total > 0 && <span className="badge text-bg-danger float-end">{notificationsNumber.total}</span>}
              </NavLink>
            </li>
            <li>
              <NavLink className="dropdown-item" to={Routes.PROFIL_PARAMETERS}>
                <FontAwesomeIcon icon={faGear} /> Paramètres
              </NavLink>
            </li>
            {user.isAdmin && (
              <>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <NavLink className="dropdown-item" id="console" to={Routes.ADMIN}>
                    <FontAwesomeIcon icon={faUserShield} /> Admin
                  </NavLink>
                </li>
              </>
            )}
            {user.isSuperAdmin && (
              <li>
                <NavLink className="dropdown-item" id="admin" to={Routes.CONSOLE}>
                  <FontAwesomeIcon icon={faTerminal} /> Console
                </NavLink>
              </li>
            )}
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <button className="dropdown-item" id="logout" onClick={logout}>
                <FontAwesomeIcon icon={faRightFromBracket} /> Déconnexion
              </button>
            </li>
          </ul>
        </li>
      )}
      {!user && !isInitialLoading && (
        <li className="nav-item">
          <a href={loginURL.toString()} className="nav-link">
            Se&nbsp;connecter
          </a>
        </li>
      )}
    </>
  );
}
